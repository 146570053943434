<template>
	<div class="home">
		<v-card>
			<v-container fluid pt-0 mt-0>
				<v-row class="blue-grey darken-4">
                    <Breadcrumbs/>
                </v-row>
				<v-row><v-col cols="12"></v-col></v-row>
                
                <v-row>
                    <v-col cols="12">
                        <h4>User Performance Data</h4>
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col cols="12" sm="12" md="4" lg="3" xl="3">
                        <v-datetime-picker datetime="date" label="Record Date" :time-picker-props="timeProps"
                            dateFormat="yyyy-MM-dd" v-model="selectedRecordTime"></v-datetime-picker>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-tabs>
                            <v-tab v-on:click="onBrokerChange(item)" v-for="item in brokerMap" :key="item.broker">
                                {{ item.broker }}
                            </v-tab>
                        </v-tabs>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col lg="12" md="11" xs="12" cols="12">
                        <v-text-field 
                            class="mr-4 mt-n2"
                            v-model="search"
                            append-icon="mdi-search"
                            label="Search"
                            single-line
                            hide-details></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <!-- <DataTable :headers="headers" :dataSource="tableData" :search="search"/> -->
                        
                        <v-data-table :search="search" :headers="headers" :items="tableData"
                            class="elevation-1"
                            :loading="tableLoading" :items-per-page="20"
                        >
                        </v-data-table>

                    </v-col>
                </v-row>

            </v-container>
        </v-card>
    </div>
</template>
<script>
import { helper } from "@components/mixins/helper";
import * as apiUserPerformance from "@components/api/pk/user-performance";
import DataTable from "./components/DataTableUserPerformance";
import moment from "moment";
moment.locale('en-au');
import Breadcrumbs from "@components/Breadcrumbs";
import {mapActions, mapState} from 'vuex';

export default {
    mixins: [helper],
    components:{
        DataTable, Breadcrumbs
    },
    data () {
        return {
            tableLoading: false,
            // groupListMissing: [],
            selectedRecordTime: null,
            userPerfList: [],
            selectedBrokerId: -1,
            search:"",
            timeProps: {
                useSeconds: false,
            },
            headers:[
                { text: "Broker Id", value: "broker_id" },
                { text: "Record Time", value: "record_time" },
                { text: "Order", value: "order" },
                { text: "Login", value: "login" },
                { text: "Book", value: "book" },
                { text: "User Group", value: "user_group" },
                { text: "symbol", value: "symbol" },
                { text: "PNL", value: "pnl" },
                { text: "Open PNL", value: "open_pnl" },
                { text: "Close PNL", value: "close_pnl" },
                { text: "Volume USD", value: "volume_usd" },
                { text: "Volume", value: "volume" },
                { text: "DPM", value: "dpm" },
                { text: "Spread Income", value: "spread_income" },
                { text: "Commission", value: "commission" }
            ]
        }
    },
    watch: {},
    computed: {
        ...mapState([
            'userLevel',
            'brokerId',
            'brokerMap'
        ]),
        tableData() {
            if (this.selectedBrokerId == -1) return this.userPerfList;

            return this.userPerfList.filter(item => item.broker_id == this.selectedBrokerId);
        }
    },
    methods: {
        onBrokerChange(data) {
            this.selectedBrokerId = data.broker_id;
        },
        loadData() {
            let self = this;
            self.tableLoading = true;
            let params = { record_date:self.selectedRecordTime.split(" ")[0] }
            apiUserPerformance.fetch(params).then(res => {
                let data = res.data;
                self.userPerfList = data;
                console.log(self.userPerfList);
                self.tableLoading = false;
            })
        }
    },
    mounted() {
        let endDate = this.getYesterday();
        this.selectedRecordTime = endDate.format("YYYY-MM-DD") + " 00:00";
        console.log(this.selectedRecordTime)
        this.selectedBroker = this.brokerMap[0].broker;
        this.loadData();
        // this.fetchSymbol();
    }
}
</script>