import moment from "moment";
export const helper = {
    methods: {
        /**
         * Trim Symbol
         * @param {*} room 
         */
        trimSymbol(symbol, rules) {
            for (let i = 0; i < rules.length; i++) {
                if (symbol.indexOf(rules[i]) != -1) {
                    return symbol.split(rules[i])[0];
                }
            }
            return symbol;
        },
        getYesterday() {
				
	        let currentDate = moment();

	        if (currentDate.days() == 0) {
	            currentDate = currentDate.subtract(2, 'days');
	        } else if (currentDate.days() == 6) {
	            currentDate = currentDate.subtract(1, 'days');
	        } else if (currentDate.days() == 1) { 
	            currentDate = currentDate.subtract(3, 'days');
	        } else {
	            currentDate = currentDate.subtract(1, 'days');
	        }

	        if (currentDate.format("MM-DD") == '01-01' || currentDate.format("YYYY-MM-DD") == '12-25') {
	            currentDate = currentDate.subtract(1, 'days');
	        }

	        return currentDate;
        },
        getTenDaysData() {
            let currentDate = moment();
            currentDate = currentDate.subtract(14, 'days');
            return currentDate;
        },
		/**
		 * check form's field is valid or not
		 * Eg: empty or null is invalid
		 * @param {*} ts
		 */
		formatDate(ts) {

			let current = new Date(ts);

			let result = '';
			let month = '' + (current.getMonth() + 1);
			let day = '' + current.getDate();
			let year = current.getFullYear();

			if (month.length < 2) month = '0' + month;
			if (day.length < 2) day = '0' + day;

			return [year, month, day].join('-');
		},
		/**
		 * check form's field is valid or not
		 * Eg: empty or null is invalid
		 * @param {*} ts
		 */
		formatTimestamp(ts) {

			let current = new Date(ts);

			let result = '';
			let month = '' + (current.getMonth() + 1);
			let day = '' + current.getDate();
			let year = current.getFullYear();

			let hour = current.getHours();
			let minutes = current.getMinutes();
			let sec = current.getSeconds();

			if (month.length < 2) month = '0' + month;
			if (day.length < 2) day = '0' + day;
			result = [year, month, day].join('-');
			result += " ";
			result += hour + ":" + minutes + ":" + sec;
			return result;
		},
		isMobile() {
			if (navigator.userAgent.match(/Android/i)
				|| navigator.userAgent.match(/webOS/i)
				|| navigator.userAgent.match(/iPhone/i)
				|| navigator.userAgent.match(/iPad/i)
				|| navigator.userAgent.match(/iPod/i)
				|| navigator.userAgent.match(/BlackBerry/i)
				|| navigator.userAgent.match(/Windows Phone/i)
			) {
				return true;
			}
			else {
				return false;
			}
		},
		isValidJson(text) {
			if (/^[\],:{}\s]*$/.test(text.replace(/\\["\\\/bfnrtu]/g, '@').
				replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
				replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
				return true;
			}
			return false;
		}
    }
}