<template>
    <div>
        <v-data-table
            :search="search"
            :headers="headers"
            :items="dataSource"
            class="elevation-1"
            show-select
            :items-per-page="itemsPerPage"
        >
        
        </v-data-table>
    </div>
</template>

<script>
import { mapState } from "vuex";
import * as apiBookGroup from "@components/api/pk/book-group";

export default {
    props: {
        search: "",
        headers: null,
        dataSource: null,
        itemsPerPage: null
    },
    data() {
        return {
            dialog: false,
            dialogDelete: false,
            desserts: [],
            editedIndex: -1,
            editedItem: {
                id: -1,
                broker_id: -1,   
                group: "",
                book: "",
            },
            searchBroker: ""
        };
    },
    computed: {
        ...mapState(["brokerMap"])
    },
    methods: {
        showBrokerName(id) {
            let data = this.brokerMap.filter(item => (item.broker_id = id));
            if (data.length != 0) {
                return data[0].broker;
            }
            return "";
        },
        editItem(item) {

            this.editedItem.id = item.id;
            this.editedItem.broker_id = item.broker_id;
            this.editedItem.group = item.group;
            this.editedItem.book = item.book;

            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.desserts.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        close() {
            this.dialog = false;
            
        },
        loadData() {
            let self = this;

            apiBookGroup.fetch().then(res => {
                let data = res.data;
                self.groupListFull = data;
            })
        },
        saveData(data) {
            console.log(data);
            let self = this;    
            // let params = {id: }

            // apiBookGroup.create().then(res => {
            //     self.loadData();
            // })
            this.close();
        }
    }
};
</script>
